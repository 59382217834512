//jQuery.noConflict();
jQuery(document).ready(function($) {
    $(document).ready(function() {

        // Popup
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            //disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
        
    });

    // Video
    $(".video-img").on("click", function() {
        var elm = $(this),
            conts = elm.contents(),
            le = conts.length,
            ifr = null;
        for (var i = 0; i < le; i++) {
            if (conts[i].nodeType == 8) ifr = conts[i].textContent;
        }
        elm.addClass("player").html(ifr);
        elm.off("click");
    });

    
});